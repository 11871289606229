
import { defineComponent, ref } from 'vue'
import { currencyToInt } from '@/utils/currencyFunctions'
import Header from '@/components/organisms/Header.vue'
import { Form } from 'vee-validate'
import SelectInput from '@/components/atom/SelectInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import FileInput from '@/components/atom/FileInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRoute, useRouter } from 'vue-router'
import { decimalRound } from '@/utils/commonFunctions'

export default defineComponent({
  components: {
    Header,
    Form,
    SelectInput,
    FileInput,
    TextInput,
    CustomButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const projectId = ref()
    projectId.value = route.params.id
    const items = [
      {
        id: 1,
        label: 'Subcontractor costs ',
        value: 'SC'
      },
      {
        id: 2,
        label: 'Project supplier costs ',
        value: 'PSC'
      },
      {
        id: 3,
        label: 'Travel and subsistence ',
        value: 'TS'
      },
      {
        id: 4,
        label: 'Other ',
        value: 'Other'
      }
    ]
    const schema = yup.object().shape({
      type: yup.string().required().label('Expense type'),
      files: yup.array().required().label('File'),
      po: yup.string().required().label('Purchase number'),
      invoice: yup
        .number()
        .typeError('Invoice value must be written as a whole number or as a decimal')
        .required()
        .label('Invoice')
    })
    async function submit(values: any) {
      const formData = new FormData()
      formData.append('documentType', values.type)
      formData.append('files', values.files[0])
      formData.append('poNumber', values.po)
      formData.append('invoice', `${currencyToInt(decimalRound(values.invoice))}`)
      const res = await authApi.addProjectExpense(formData, projectId.value)
      if (res) {
        router.go(-1)
      }
    }
    return { items, schema, submit }
  }
})
