<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" enctype="multipart/form-data">
      <div class="add-expense">
        <h2>Add an expense</h2>
        <p>Add a supplier invoice or travel expense type here.</p>
        <label>Expense type</label>
        <SelectInput selectLabel="Select" :items="items" name="type" errorMessage="Expense type is required" />
        <TextInput name="po" type="text" placeholderText="PO no." label="Purchase order number" />
        <TextInput name="invoice" type="text" placeholderText="Value" label="Invoice value" />
      </div>
      <div class="upload-button">
        <img src="@/assets/icons/document.svg" />
        <CustomButton label="Add a file" color="white" iconClass="plus" />
        <span>File should be .pdf or .doc format and under 2Mb</span>
        <FileInput type="file" name="files" accept=".pdf, .doc, .png" />
      </div>
      <div class="action">
        <CustomButton label="Cancel" color="grey" @onClick="$router.go(-1)" :iconVisible="false" />
        <CustomButton type="submit" label="Upload expense" color="blue" />
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { currencyToInt } from '@/utils/currencyFunctions'
import Header from '@/components/organisms/Header.vue'
import { Form } from 'vee-validate'
import SelectInput from '@/components/atom/SelectInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import FileInput from '@/components/atom/FileInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRoute, useRouter } from 'vue-router'
import { decimalRound } from '@/utils/commonFunctions'

export default defineComponent({
  components: {
    Header,
    Form,
    SelectInput,
    FileInput,
    TextInput,
    CustomButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const projectId = ref()
    projectId.value = route.params.id
    const items = [
      {
        id: 1,
        label: 'Subcontractor costs ',
        value: 'SC'
      },
      {
        id: 2,
        label: 'Project supplier costs ',
        value: 'PSC'
      },
      {
        id: 3,
        label: 'Travel and subsistence ',
        value: 'TS'
      },
      {
        id: 4,
        label: 'Other ',
        value: 'Other'
      }
    ]
    const schema = yup.object().shape({
      type: yup.string().required().label('Expense type'),
      files: yup.array().required().label('File'),
      po: yup.string().required().label('Purchase number'),
      invoice: yup
        .number()
        .typeError('Invoice value must be written as a whole number or as a decimal')
        .required()
        .label('Invoice')
    })
    async function submit(values: any) {
      const formData = new FormData()
      formData.append('documentType', values.type)
      formData.append('files', values.files[0])
      formData.append('poNumber', values.po)
      formData.append('invoice', `${currencyToInt(decimalRound(values.invoice))}`)
      const res = await authApi.addProjectExpense(formData, projectId.value)
      if (res) {
        router.go(-1)
      }
    }
    return { items, schema, submit }
  }
})
</script>
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
  @media( max-width: $md-breakpoint)
    padding: 0 1.5rem
.add-expense
  max-width: 370px
  margin: 1.5rem auto
.action
  background: $white
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 0.8rem
  position: fixed
  bottom: 0
  left: 0
  z-index: 2
  box-shadow: 0px -8px 36px rgba(14, 30, 54, 0.05)
button
  max-width: 15rem
  width: max-content
  &:not(:last-child)
    margin-right: 1rem
</style>
